/*******************************
            General
*******************************/
.centeredContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}
/*******************************
            BaseImage
*******************************/
.base-image-container {
  height: 100%;
  width: 100%;
  background-color: #fafafa;
}
.base-image {
  height: 100%;
  width: 100%;
}
.base-image-inverted {
  filter: invert(100%);
}
.base-image-contain {
  height: 100%;
  object-fit: contain;
}
.base-image-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.base-image-background {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  text-align: center;
}
.base-image-container-effect {
  overflow: hidden;
}
.base-image-effect {
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.base-image-container-effect:hover .base-image-effect,
.base-image-container-effect:focus .base-image-effect {
  -ms-transform: scale(1.04);
  -moz-transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -o-transform: scale(1.04);
  transform: scale(1.04);
}
.base-image-background-show-on-hover {
  opacity: 0 !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.base-image-container-effect:hover .base-image-background-show-on-hover,
.base-image-container-effect:focus .base-image-background-show-on-hover {
  opacity: 1 !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 4rem !important;
  transition: 0.2s !important;
}
